import React from "react";
import { Container, Confirmations } from "./styles";

interface IUserCard {
    title: string; 
    subtitle: string; 
    children?: React.ReactNode; 
}

const Content: React.FC<IUserCard> = ({title, subtitle ,children}) => {

    return (
        <Container>
            <div>
                <span>{title}</span>
                <small>{subtitle}</small>
            </div>
            
            <Confirmations>
                {children}
            </Confirmations>
        </Container>
    );
};

export default Content;