import styled from "styled-components";

export const Container = styled.div`
    .hh-grayBox {
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.colors.tertiary};
    }

    .pt45 {
        padding-top: 30px;
    }

    .order-tracking {
        text-align: center;
        width: 25%;
        position: relative;
        display: block;
    }

    .order-tracking .is-complete {
        display: block;
        position: relative;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        border: 0px solid ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.warning};;
        margin: 0 auto;
        transition: background 0.25s linear;
        -webkit-transition: background 0.25s linear;
        z-index: 2;
    }

    .order-tracking .is-complete:after {
        display: block;
        position: absolute;
        content: '';
        height: 14px;
        width: 7px;
        top: -2px;
        bottom: 0;
        left: 5px;
        margin: auto 0;
        border: 0px solid ${props => props.theme.colors.white};
        border-width: 0px 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
    }

    .order-tracking.completed .is-complete {
        border-color: ${props => props.theme.colors.success};
        border-width: 0px;
        background-color: ${props => props.theme.colors.success};
    }

    .order-tracking.completed .is-complete:after {
        border-color: #fff;
        border-width: 0px 3px 3px 0;
        width: 7px;
        left: 11px;
        opacity: 1;
    }

    .order-tracking p {
        color:${props => props.theme.colors.white};
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 0;
        line-height: 20px;
        font-weight: 500;
    }

    .order-tracking p span {
        font-size: 14px;
    }

    .order-tracking.completed p {
        color: ${props => props.theme.colors.white};
    }

    .order-tracking::before {
        content: '';
        display: block;
        height: 3px;
        width: calc(100% - 40px);
        background-color: ${props => props.theme.colors.warning};
        top: 13px;
        position: absolute;
        left: calc(-50% + 20px);
        z-index: 0;
    }

    .order-tracking:first-child:before {
        display: none;
    }

    .order-tracking.completed:before {
        background-color: ${props => props.theme.colors.success};
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .container {
        max-width: 100%;
        padding: 0;
        margin: 0;
    }

    .cancelada{
        font-size: 18px;
        margin-bottom: 20px;
    }

    @media(max-width: 770px){
        .order-tracking p {
            font-size: 12px;
        }
    }

    @media(max-width: 770px){
        .order-tracking p {
            font-size: 11px;
        }
    }
`;