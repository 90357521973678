import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
`;

export const TitleContainer = styled.div`
    > h1 {
        color: ${props => props.theme.colors.white};
        font-size: 24px;
        font-weight: 700;

        &::after {
            content: '';
            display: block;
            width: 55px;
            border-bottom: 10px solid ${props => props.theme.colors.info};
            margin-top: 8px;
        }
    }

    @media(max-width: 780px) {
        > h1{
            font-size: 20px;
        } 
    }
`;

export const Controllers = styled.div`
    display: flex;
    gap: 10px; 
    align-items: center;

    .create, .register, .register-user{
        background-color: ${props => props.theme.colors.tertiary};
        color: ${props => props.theme.colors.white};
        padding: 7px 10px;
        border-radius: 5px;
        border: 1px solid ${props => props.theme.colors.tertiary};
        margin-right: 7px;
        align-items: center;
        display: flex;
        transition: background-color 0.3s ease;

        .add-icon{
            font-size: 16px;
            margin-left: 5px;
        }
    }

    .create {
        width: 140px;
    }
    .register {
        width: 160px;
    }
    .register-user{
        width: 150px;
    }

    .create:hover, .register:hover, .register-user:hover{
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.info};
    }


    @media(max-width: 990px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
