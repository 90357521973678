import styled from "styled-components";

export const Container = styled.div`
    > select {
        width: 300px;
        padding: 7px 10px;
        border-radius: 5px;
    }
    
    @media (max-width:1185px){
        > select {
            width: 200px;
        }
    }

    @media (max-width:650px){
        > select {
            width: 150px;
        }
    }
`;