import { useState } from 'react';
import React from "react";
import Toogle from '../Toogle';
import { Container, Profile, Welcome, UserName } from "./styles";
import { useTheme } from '../../hooks/theme';
import { useAuth } from '../../hooks/auth';

const MainHeader: React.FC = () => {
    const { toggleTheme, theme } = useTheme();
    const { nome_exibicao } = useAuth();

    const handleChangeTheme = () => {
        setDarkTheme(!darkTheme);
        toggleTheme();
    }

    const [darkTheme, setDarkTheme] = useState(() => theme.title === 'dark' ? true : false);

    return (
        <Container>
            <Toogle
                labelLeft="Dark"
                labelRight="Light"
                checked={darkTheme}
                onChange={handleChangeTheme}
            />
            
            <Profile>
                <Welcome>Olá, </Welcome>
                <UserName>{nome_exibicao}</UserName>
            </Profile>
        </Container>
    )
};

export default MainHeader;