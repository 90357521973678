import React from 'react';
import { Container, Input } from './styles';

interface ISearchInputProps {
    onSearch: (searchTerm: string) => void;
}

const SearchInput: React.FC<ISearchInputProps> = ({ onSearch }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSearch(event.target.value);
    };

    return (
        <Container>
            <Input
                type="text"
                onChange={handleChange}
                placeholder="Pesquisar por protocolo"
            />
        </Container>
    );
};

export default SearchInput;