import React from "react";
import { Container, SideLeft, SideRight, LegendContainer, Legend } from "./styles";
import { ResponsiveContainer, BarChart, Bar, Cell, Tooltip } from "recharts";

interface IBarChartProps {
    title: string;
    data: {
        name: string;
        total: number;
        percent: number;
        color: string;
    }[],
}

const BarChartBox: React.FC <IBarChartProps> = ({title, data}) => (
    <Container>
        <SideLeft>
            <h2>{title}</h2>
            <LegendContainer>
            {
                data.map(indicator => (
                    <Legend 
                        key={indicator.name} 
                        color={indicator.color}>
                        <div>{indicator.percent}%</div>
                        <span>{indicator.name}</span>
                    </Legend>
                ))
            }
            </LegendContainer>
        </SideLeft>
        
        <SideRight>
            <ResponsiveContainer>
                <BarChart data={data}>
                    <Bar dataKey="total" name={"Solicitações"}>
                        {data.map((indicator) => (
                            <Cell
                                key={indicator.name}
                                cursor="pointer"
                                fill={indicator.color}
                            />
                        ))}
                    </Bar>
                    <Tooltip 
                        cursor={{fill:'none'}}
                        formatter={(value) => Number(value)}
                    />
                </BarChart>
            </ResponsiveContainer>
        </SideRight>
    </Container>
);

export default BarChartBox;