import React, { useState, useEffect } from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import config from '../../config';

interface ISwapCancelProps {
    isOpen: boolean;
    onClose: () => void;
    data: any;
    onUpdate: (updatedRequest: any) => void;
}

const SwapCancel: React.FC<ISwapCancelProps> = ({ isOpen, onClose, data, onUpdate }) => {
    const [cancellationReason, setCancellationReason] = useState<string>('');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (!isOpen) {
            setCancellationReason('');
            setError('');
        }
    }, [isOpen]);

    const handleCancel = async () => {
        if (!cancellationReason.trim()) {
            setError('O motivo do cancelamento é obrigatório.');
            return;
        }

        try {
            const updatedData = { 
                ...data, 
                status: 'Troca cancelada',
                status_wpp: 'enviar_5',
                detalhe: cancellationReason.trim()
            };
            await axios.put(`${config.solicitacoesApiUrl}${data.id_solicitacao}/`, updatedData);
            onUpdate(updatedData); 
            window.location.reload(); 
        } catch (error) {
            console.error('Erro ao cancelar a troca:', error);
            setError('Ocorreu um erro ao cancelar a troca. Por favor, tente novamente.');
        }
    };

    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={onClose}>
                    <MdClose />
                </CloseButton>
                <h2>Confirmar cancelamento de troca?</h2>
                
                <div className="input-group">
                    <textarea
                        id="cancellationReason"
                        value={cancellationReason}
                        onChange={(e) => {
                            setCancellationReason(e.target.value);
                            if (error) setError('');
                        }}
                        required
                        placeholder="Digite o motivo do cancelamento"
                    />
                    {error && <span style={{ color: 'red', fontSize: '12px', display: 'block', maxWidth: '100%' }}>{error}</span>}
                </div>

                <div className="button-group" style={{ marginTop: '16px' }}>
                    <button className="yes" onClick={handleCancel}>Sim</button>
                    <button className="no" onClick={onClose}>Não</button>
                </div>
            </Content>
        </Container>
    );
};

export default SwapCancel;