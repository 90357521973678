import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.main``;

export const Confirmations = styled.div`
> button {
        padding: 7px 15px;
        border-radius: 5px;
        margin-left: 7px;
    }

    > .details, .edit, .cancel{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: ${props => props.theme.colors.primary};
        font-size: 14px;
        color: ${props => props.theme.colors.info};
        cursor: pointer;
        transition: opacity .3s;

        &:hover{
            opacity: .8;
        }
    }

    > .yes, .no {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 55px; 
        height: 30px;
        cursor: pointer;
        transition: opacity .3s;

        &:hover{
            opacity: .5;
        }
    }

    @media (max-width: 850px) {
        > button {
            padding: 4px 8px;
        }
        > .details, .edit, .cancel {
            display: block;
            width: 100%; 
            margin: 5px 0;
            text-align: center;
            font-size: 12px;
        }
    }

    @media (max-width: 600px) {
        > button {
            padding: 7px 15px;
        }
        > .details, .edit, .cancel {
            display: inline-flex;
            width: auto;
            margin: 5px;
        }
        
    }

    @media (max-width: 470px) {
        > button {
            padding: 4px 8px;
        }
        > .details, .edit, .cancel {
            display: block;
            width: 100%; 
            margin: 5px 0;
            text-align: center;
            font-size: 12px;
        }
    }
`;
