import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    background-color: ${props => props.theme.colors.tertiary};
    border-radius: 15px;
    width: 500px;
    padding: 30px;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    > h2 {
        color: ${props => props.theme.colors.white};
        position: relative;
        margin-top: -50px;
        font-size: 24px;
    }

    .button-group {
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-top: 40px;
    }

    button {
        height: 25px;
        width: 100px;
        border-radius: 5px;
        background-color: ${props => props.theme.colors.info};
        color: #FFF;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    button.yes:hover {
        background-color: ${props => props.theme.colors.success};
    }

    button.no:hover {
        background-color: ${props => props.theme.colors.warning};
    }

    @media(max-width: 800px){
        width: 400px;

        > h2 {
            font-size: 18px;
        }

        button {
            height: 30px;
            width: 80px;
            font-size: 16px;
        }

        textarea{
            height: 80px;
            margin-right: 80px;
        }
    }

    @media(max-width: 470px){
        width: 300px;

        > h2 {
            font-size: 12px;
        }

        .button-group {
            gap: 30px;
        }

        button {
            height: 25px;
            width: 70px;
            font-size: 12px;
        }

        textarea{
            height: 70px;
            margin-right: 50px;
        }
    }
`;

export const CloseButton = styled.div`
    color: ${props => props.theme.colors.info};
    background-color: ${props => props.theme.colors.primary};
    border-radius: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
    transition: opacity .3s;
    &:hover {
        opacity: .8;
    }
`;