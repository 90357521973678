import React from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';
import SwapTracking from '../SwapTracking';

interface ISwapDetailsProps {
    isOpen: boolean;
    onClose: () => void;
    data: any; 
}

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const SwapDetails: React.FC<ISwapDetailsProps> = ({ isOpen, onClose, data }) => {
    if (!isOpen) return null;

    const listaFarmacias: string[] = [
        `${data.nome_pharm_a} \nCNPJ: ${data.cnpj_pharm_a} \nUnidade: ${data.pharm_a}`,
        `${data.nome_pharm_b} \nCNPJ: ${data.cnpj_pharm_b} \nUnidade: ${data.pharm_b}`,
        `${data.nome_pharm_c} \nCNPJ: ${data.cnpj_pharm_c} \nUnidade: ${data.pharm_c}`
    ];
    const formattedDate = formatDate(data.dt_criacao);

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={onClose}><MdClose/></CloseButton>
                <h2>Detalhes da Solicitação</h2>
                <>
                    <p className='title'><strong>Protocolo:</strong></p> <p>{data.protocolo_sanofi}</p>
                    <p className='title'><strong>Nome:</strong></p> <p>{data.nome_cliente}</p>
                    <p className='title'><strong>CPF:</strong></p> <p>{data.cpf}</p>
                    <p className='title'><strong>Email:</strong></p> <p>{data.email}</p>
                    <p className='title'><strong>Celular:</strong></p> <p>{data.telefone_cliente}</p>
                    <p className='title'><strong>CEP:</strong></p> <p>{data.cep_cliente}</p>
                    <p className='title'><strong>Status:</strong></p> <p>{data.status}</p>
                    <p className='title'><strong>Medicamento:</strong></p> <p>{data.nome_medicamento}</p>
                    <p className='title'><strong>EAN:</strong></p> <p>{data.ean}</p>
                    <p className='title'><strong>Data de criação:</strong></p> <p>{formattedDate}</p>
                    <p className='title'><strong>Farmácia(s):</strong></p> 
                    <p className='farmacias'>
                        {
                            listaFarmacias[0] === " \nCNPJ:  \nUnidade: 0" && listaFarmacias[1] === " \nCNPJ:  \nUnidade: 0" && listaFarmacias[2] === " \nCNPJ:  \nUnidade: 0" ? "Sem farmácias definidas" :
                            listaFarmacias[0] != " \nCNPJ:  \nUnidade: 0" && listaFarmacias[1] === " \nCNPJ:  \nUnidade: 0" && listaFarmacias[2] === " \nCNPJ:  \nUnidade: 0" ? listaFarmacias[0] :
                            listaFarmacias[1] != " \nCNPJ:  \nUnidade: 0" && listaFarmacias[0] === " \nCNPJ:  \nUnidade: 0" && listaFarmacias[2] === " \nCNPJ:  \nUnidade: 0" ? listaFarmacias[1] :
                            listaFarmacias[2] != " \nCNPJ:  \nUnidade: 0" && listaFarmacias[0] === " \nCNPJ:  \nUnidade: 0" && listaFarmacias[1] === " \nCNPJ:  \nUnidade: 0" ? listaFarmacias[2] :
                            listaFarmacias[0] != " \nCNPJ:  \nUnidade: 0" && listaFarmacias[1] != " \nCNPJ:  \nUnidade: 0" && listaFarmacias[2] === " \nCNPJ:  \nUnidade: 0" ? listaFarmacias[0]+'\n\n'+listaFarmacias[1] :
                            listaFarmacias[0] != " \nCNPJ:  \nUnidade: 0" && listaFarmacias[2] != " \nCNPJ:  \nUnidade: 0" && listaFarmacias[1] === " \nCNPJ:  \nUnidade: 0" ? listaFarmacias[0]+'\n\n'+listaFarmacias[2] :
                            listaFarmacias[1] != " \nCNPJ:  \nUnidade: 0" && listaFarmacias[2] != " \nCNPJ:  \nUnidade: 0" && listaFarmacias[0] === " \nCNPJ:  \nUnidade: 0" ? listaFarmacias[1]+'\n\n'+listaFarmacias[2] :
                            listaFarmacias.join('\n\n')
                        }
                    </p>
                    <p className='title'><strong>Detalhes:</strong></p> <p>{data.detalhe}</p>
                </>
                
                <div className='swap-tracking'>
                    <SwapTracking status={data.status} />
                </div>
            </Content>
        </Container>
    );
};

export default SwapDetails;