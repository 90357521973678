import styled from "styled-components";

interface IContainerProps {
    color: string;
}

export const Container = styled.div<IContainerProps>`
    background-color: ${props => props.color};
    width: 19.0%;
    height: 180px;
    margin: 10px 0;
    color: ${props => props.theme.colors.white};
    border-radius: 7px;
    padding: 10px 20px;
    position: relative;
    overflow: hidden;

    > img {
        height: 100%;
        position: absolute;
        top: 0px;
        right: -15px;
        opacity: .5;
        z-index: 0;
    }

    > span {
        white-space: pre-line;
        font-size: 16px;
        font-weight: 800;
        position: relative;
        z-index: 1;
    }

    > h1 {
        position: absolute;
        z-index: 1;
        bottom: 50px;

        > .count {
            font-size: 20px;
        }
    }

    @media(max-width: 1600px){
        > img {
            height: 80%;
            top: 28px;
            right: -25px;
        }
    }

    @media(max-width: 1200px){

        height: 250px;

        > img {
            height: 50%;
            top: 110px;
            right: 10%;
        }

        > span {
            left: -10px;
            font-size: 14px;
        }

        > h1 {
            word-wrap: break-word;
            top: 65px;
            left: 10px;

            > .count {
                font-size: 16px;
            }
        }
        
    }

    @media(max-width: 1000px) {
        height: 180px;

        > img {
            height: 40%;
            top: 100px;
            right: 20%;
        }

        > span {
            font-size: 12px;
        }

        > h1 {
            > .count {
                font-size: 14px;
            }
        }
    }

    @media(max-width: 840px) {

        > img {
            height: 40%;
            top: 100px;
            right: 25%;
        }

        > span {
            top: 0px;
            font-size: 12px;
        }

        > h1 {
            top: 60px;
            > .count {
                font-size: 14px;
            }
        }
    }

    @media(max-width: 630px){
        height: 160px;

        > span {
            font-size: 9px;
        }

        > img {
            height: 30%;
            top: 110px;
            right: 30%;
        }

        >h1{
            > .count {
                font-size: 10px;
            }
        }
    }

    @media(max-width: 508px){

        width: 100%;

        > img {
            height: 80%;
            top: 30px;
            right: 0px;
        }

        > span {
            top: 5px;
            font-size: 18px;
        }

        > h1 {
            word-wrap: break-word;
            top: 80px;

            > .count {
                font-size: 20px;
            }
        }
    }
`;