import React from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';

interface IPharmDetailsProps {
    isOpen: boolean;
    onClose: () => void;
    data: any; 
}

const PharmDetails: React.FC<IPharmDetailsProps> = ({ isOpen, onClose, data }) => {
    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={onClose}><MdClose/></CloseButton>
                <h2>Detalhes da Farmácia</h2>
                <>
                    <p className='title'><strong>Unidade:</strong></p> <p>{data.unidade}</p>
                    <p className='title'><strong>Nome:</strong></p> <p>{data.nome_farmacia}</p>
                    <p className='title'><strong>CNPJ:</strong></p> <p>{data.cnpj}</p>
                    <p className='title'><strong>Celular:</strong></p> <p>{data.telefone_farmacia}</p>
                    <p className='title'><strong>CEP:</strong></p> <p>{data.cep_farmacia}</p>
                    <p className='title'><strong>Endereço:</strong></p> <p>{data.rua_farmacia}</p>
                    <p className='title'><strong>Cidade:</strong></p> <p>{data.cidade}</p>
                </>
            </Content>
        </Container>
    );
};

export default PharmDetails;