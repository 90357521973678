import React from 'react';
import { Container, Input } from './styles';

interface ISearchInputPharmProps {
    onSearch: (searchTerm: string) => void;
}

const SearchInputPharm: React.FC<ISearchInputPharmProps> = ({ onSearch }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSearch(event.target.value);
    };

    return (
        <Container>
            <Input
                type="text"
                onChange={handleChange}
                placeholder="Pesquisar pela unidade"
            />
        </Container>
    );
};

export default SearchInputPharm;