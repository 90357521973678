import React, { useState, useEffect } from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import config from '../../config';
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";

interface IUserEditProps {
    isOpen: boolean;
    onClose: () => void;
    data: any;
    onUpdate: (updatedRequest: any) => void;
}

const UserEdit: React.FC<IUserEditProps> = ({ isOpen, onClose, data, onUpdate }) => {
    const [formData, setFormData] = useState({
        id_user: '',
        email: '',
        senha: '',
        nome: '',
        tipo_user: '',
        unidade_usuario: ''
    });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setFormData({
                id_user: data.id_user || '',
                email: data.email || '',
                senha: data.senha || '',
                nome: data.nome || '',
                tipo_user: data.tipo_user || '',
                unidade_usuario: data.unidade_usuario || ''
            });
            setErrorMessage(null); 
        } else {
            resetForm();
        }
    }, [isOpen, data]);

    const resetForm = () => {
        setFormData({
            id_user: '',
            email: '',
            senha: '',
            nome: '',
            tipo_user: '',
            unidade_usuario: ''
        });
        setErrorMessage(null); 
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const validateForm = () => {
        const { email, senha, nome, unidade_usuario, tipo_user } = formData;

        if (!email || !senha || !nome || !unidade_usuario) {
            return 'Todos os campos são obrigatórios.';
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Email inválido.';
        }

        const unidadeNumero = Number(unidade_usuario);

        if ((isNaN(unidadeNumero) || !Number.isInteger(unidadeNumero) || unidadeNumero === 0) && (tipo_user === 'farmacia')) {
            return 'A unidade deve ser um número inteiro e não pode ser 0.';
        }

        return null;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const validationError = validateForm();
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        try {
            await axios.put(`${config.usuariosApiUrl}${data.id_user}/`, formData);
            onUpdate(formData);
            console.log(formData);
            window.location.reload();
        } catch (error) {
            setErrorMessage('Erro ao editar dados do usuário. Verifique os dados.');
            console.error('Erro ao atualizar dados:', error);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev);
    };

    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={onClose}>
                    <MdClose />
                </CloseButton>
                <h2>Editar Usuário</h2>
                <form onSubmit={handleSubmit}>

                    <p className='title'><strong>Nome:</strong></p>
                    <input
                        type="text"
                        name="nome"
                        value={formData.nome}
                        onChange={handleChange}
                    />

                    {data.tipo_user === "farmacia" && (
                        <>
                            <p className='title'><strong>Unidade:</strong></p>
                            <input
                                type="text"
                                name="unidade_usuario"
                                value={formData.unidade_usuario}
                                onChange={handleChange}
                            />
                        </>
                    )}

                    <p className='title'><strong>Email:</strong></p>
                    <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>Senha:</strong></p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="senha"
                            value={formData.senha}
                            onChange={handleChange}
                        />
                        <div onClick={togglePasswordVisibility} style={{ marginLeft: '10px', cursor: 'pointer' }}>
                            {showPassword ? <IoMdEyeOff size={20} /> : <IoEye size={20} />}
                        </div>
                    </div>

                    {errorMessage && (
                        <div style={{ fontSize: '16px', color: 'red', textAlign: 'center', gridColumn: 'span 4' }}>
                            {errorMessage}
                        </div>
                    )}

                    <button type="submit">Salvar</button>
                </form>
            </Content>
        </Container>
    );
};

export default UserEdit;