import React, { useState, useEffect } from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import config from '../../config';

interface IUserCreateProps {
    isOpen: boolean;
    onClose: () => void;
}

const tiposOptions = [
    { value: 'sanofi', label: 'Sanofi' },
    { value: 'farmacia', label: 'Farmácia' }
];

const UserCreate: React.FC<IUserCreateProps> = ({ isOpen, onClose }) => {
    const initialFormData = {
        email: '',
        senha: '',
        nome: '',
        tipo_user: 'farmacia', 
        unidade_usuario: ''
    };

    const [formData, setFormData] = useState(initialFormData);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (isOpen) {
            setFormData(initialFormData);
        }
    }, [isOpen]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
    
        if (name === 'tipo_user') {
            if (value === 'sanofi') {
                setFormData(prevData => ({
                    ...prevData,
                    [name]: value,
                    unidade_usuario: '0' 
                }));
            } else {
                setFormData(prevData => ({
                    ...prevData,
                    [name]: value 
                }));
            }
        } else {
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    const validateForm = () => {
        const { email, senha, nome, unidade_usuario, tipo_user } = formData;
    
        if (!email || !senha || !nome || (!unidade_usuario && tipo_user !== 'sanofi')) {
            return 'Todos os campos são obrigatórios.';
        }
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Email inválido.';
        }
    
        if (tipo_user !== 'sanofi') {
            const unidadeNumero = Number(unidade_usuario);
            if (isNaN(unidadeNumero) || !Number.isInteger(unidadeNumero) || unidadeNumero === 0) {
                return 'A unidade deve ser um número inteiro e não pode ser igual a 0.';
            }
        }
    
        return null;
    };
    

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const validationError = validateForm();
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        const payload = { ...formData };

        try {
            await axios.post(`${config.usuariosApiUrl}`, payload);
            window.location.reload();
        } catch (error) {
            setErrorMessage('Erro ao cadastrar usuário. Verifique os dados.');
            console.error('Erro ao cadastrar usuário:', error);
        }
    };

    const handleClose = () => {
        setErrorMessage(null);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={handleClose}>
                    <MdClose />
                </CloseButton>
                <h2>Cadastrar Usuário</h2>

                <form onSubmit={handleSubmit}>
                    <p className='title'><strong>Nome:</strong></p>
                    <input
                        type="text"
                        name="nome"
                        value={formData.nome}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>Permissão:</strong></p>
                    <select
                        name="tipo_user"
                        value={formData.tipo_user}
                        onChange={handleChange}
                    >
                        {tiposOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>

                    <p className='title'><strong>Email:</strong></p>
                    <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>Senha:</strong></p>
                    <input
                        type="text"
                        name="senha"
                        value={formData.senha}
                        onChange={handleChange}
                    />

                    {formData.tipo_user !== 'sanofi' && (
                        <>
                            <p className='title'><strong>Unidade:</strong></p>
                            <input
                                type="text"
                                name="unidade_usuario"
                                value={formData.unidade_usuario}
                                onChange={handleChange}
                            />
                        </>
                    )}

                    {errorMessage && (
                        <div style={{ fontSize: '16px', color: 'red', textAlign: 'center', gridColumn: 'span 4' }}>
                            {errorMessage}
                        </div>
                    )}

                    <button type="submit" style={{ marginTop: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                        Cadastrar
                    </button>
                </form>
            </Content>
        </Container>
    );
};

export default UserCreate;
