import React, { useState } from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import config from '../../config';

interface IPharmCreateProps {
    isOpen: boolean;
    onClose: () => void;
}

const PharmCreate: React.FC<IPharmCreateProps> = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        id_farmacia: '',
        unidade: '',
        cnpj: '',
        cep_farmacia: '',
        cidade: '',
        nome_farmacia: '',
        telefone_farmacia: '',
        rua_farmacia: ''
    });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleCepChange = async (cep: string) => {
        setFormData(prevData => ({ ...prevData, cep_farmacia: cep }));
    
        if (cep.length === 8) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                const { logradouro, bairro, localidade } = response.data;
    
                if (!logradouro) {
                    setFormData(prevData => ({
                        ...prevData,
                        rua_farmacia: '',
                        cidade: '',
                    }));
                } else {
                    setFormData(prevData => ({
                        ...prevData,
                        rua_farmacia: `${logradouro}, 000 - ${bairro || ''}`, 
                        cidade: localidade
                    }));
                }
            } catch (error) {
                console.error('Erro ao buscar o CEP:', error);
                setErrorMessage('Erro ao buscar o endereço. Verifique o CEP.');
            }
        }
    };

    const validateForm = () => {
        const {
            nome_farmacia,
            cnpj,
            telefone_farmacia,
            cep_farmacia,
            rua_farmacia,
            cidade
        } = formData;
    
        if (!nome_farmacia || !cnpj || !telefone_farmacia || !cep_farmacia || !rua_farmacia || !cidade) {
            return 'Todos os campos são obrigatórios.';
        }
    
        if (nome_farmacia.length > 50) {
            return 'Nome de farmácia inválido.';
        }
        if (cnpj.length !== 14) {
            return 'CNPJ inválido (Digite apenas números).';
        }

        return null;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const validationError = validateForm();
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        const payload = {
            ...formData
        };

        try {
            await axios.post(`${config.farmaciasApiUrl}`, payload);
            window.location.reload();
        } catch (error) {
            setErrorMessage('Erro ao cadastrar farmácia. Verifique os dados.'); 
            console.error('Erro ao cadastrar farmácia:', error);
        }
    };

    const handleClose = () => {
        setFormData({
            id_farmacia: '',
            unidade: '',
            cnpj: '',
            cep_farmacia: '',
            cidade: '',
            nome_farmacia: '',
            telefone_farmacia: '',
            rua_farmacia: ''
        });
        setErrorMessage(null); 
        onClose();
    };

    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={handleClose}>
                    <MdClose />
                </CloseButton>
                <h2>Cadastrar Farmácia</h2>

                <form onSubmit={handleSubmit}>

                    <p className='title'><strong>Nome:</strong></p>
                    <input
                        type="text"
                        name="nome_farmacia"
                        value={formData.nome_farmacia}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>CNPJ:</strong></p>
                    <input
                        type="text"
                        name="cnpj"
                        value={formData.cnpj}
                        onChange={handleChange}
                        maxLength={14}
                    />

                    <p className='title'><strong>Celular:</strong></p>
                    <input
                        type="text"
                        name="telefone_farmacia"
                        value={formData.telefone_farmacia}
                        onChange={handleChange}
                        maxLength={13}
                    />

                    <p className='title'><strong>CEP:</strong></p>
                    <input
                        type="text"
                        name="cep_farmacia"
                        value={formData.cep_farmacia}
                        onChange={e => handleCepChange(e.target.value)} 
                        maxLength={8}
                    />

                    <p className='title'><strong>Endereço:</strong></p>
                    <input
                        type="text"
                        name="rua_farmacia"
                        value={formData.rua_farmacia}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>Cidade:</strong></p>
                    <input
                        type="text"
                        name="cidade"
                        value={formData.cidade}
                        onChange={handleChange}
                    />

                    {errorMessage && (
                        <div style={{ fontSize: '16px', color: 'red', textAlign: 'center', gridColumn: 'span 4' }}>
                            {errorMessage}
                        </div>
                    )}

                    <button type="submit" style={{ marginTop: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                        Cadastrar
                    </button>
                </form>
            </Content>
        </Container>
    );
};

export default PharmCreate;