import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Input = styled.input`
    padding: 7px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 300px;

    @media (max-width:1185px){
        width: 207px;
    }

    @media (max-width:650px){
        width: 157px;
        font-size: 12px;
    }
`;