import React, { useState } from "react";
import { Container, Logo, Form, FormTitle } from "./styles";
import logoDark from '../../assets/logoTemaDark.png';
import logoLight from '../../assets/logoTemaLight.png';
import InputSignIn from "../../components/InputSignIn";
import ButtonSignIn from "../../components/ButtonSignIn";
import { useAuth } from '../../hooks/auth';
import { useTheme } from "../../hooks/theme";

const SignIn: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const { theme } = useTheme();

    const { signIn } = useAuth();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); 
        signIn(email, password);
    };

    return (
        <Container>
            {theme.title === 'dark' && (
                <Logo src={logoDark} alt="Logo SAC AI" />
            )}
            {theme.title === 'light' && (
                <Logo src={logoLight} alt="Logo SAC AI" />
            )}

            <Form onSubmit={handleSubmit}>
                <FormTitle>Entrar</FormTitle>

                <InputSignIn
                    type="email"
                    required
                    placeholder="email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <InputSignIn
                    type="password"
                    required
                    placeholder="senha"
                    onChange={(e) => setPassword(e.target.value)}
                />

                <ButtonSignIn type="submit">Acessar</ButtonSignIn>
            </Form>
        </Container>
    );
}

export default SignIn;