import React from "react";
import { Container, Confirmations } from "./styles";

interface IPharmCard {
    title: string; 
    subtitle: string; 
    cnpj: string;
    children?: React.ReactNode; 
}

const Content: React.FC<IPharmCard> = ({title, subtitle, cnpj ,children}) => {

    return (
        <Container>
            <div>
                <span>#{title}</span>
                <small>{subtitle}</small>
            </div>
            <div className="infos">
                <small>CNPJ: {cnpj}</small>
            </div>
            
            <Confirmations>
                {children}
            </Confirmations>
        </Container>
    );
};

export default Content;