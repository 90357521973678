import React, { useMemo, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ContentHeader from "../../components/ContentHeader";
import SelectInput from "../../components/SelectInput";
import SearchInputSwap from "../../components/SearchInputSwap";
import { Confirmations, Container, Content, Filters } from "./styles";
import SwapCard from "../../components/SwapCard";
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa6';
import { useAuth } from '../../hooks/auth';
import SwapDetails from "../../components/SwapDetails";
import SwapEdit from "../../components/SwapEdit";
import config from "../../config";
import SwapCreate from "../../components/SwapCreate";
import { FcPlus } from "react-icons/fc";
import SwapCancel from "../../components/SwapCancel";
import axios from 'axios';
import SwapConfirmation from "../../components/SwapConfirmation"

interface IData {
    id_solicitacao: string;
    type: string;
    status: string;
    medicamento: string;
    childrenValidation: boolean;
    tagColor: string;
    num_protocolo: string;
    ean: string;
    fullDetails: any;
    criation: string;
}

const ListSwap: React.FC = () => {
    const { tipo } = useAuth(); 
    const { unidade_usuario } = useAuth();
    const [data, setData] = useState<IData[]>([]);
    const [statusSelected, setStatusSelected] = useState<string>('Todas');
    const [searchTerm, setSearchTerm] = useState<string>(''); 
    const [selectedRequest, setSelectedRequest] = useState<any>(null); 
    const [isDetailsOpen, setIsDetailsOpen] = useState(false); 
    const [isEditOpen, setIsEditOpen] = useState(false); 
    const [isCancelOpen, setIsCancelOpen] = useState(false); 
    const [isCreateOpen, setIsCreateOpen] = useState(false); 
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); 
    const { type } = useParams();

    const titleOptions = useMemo(() => {
        return { title: 'Solicitações de troca' };
    }, [type]);

    const status = [
        { value: 'Todas', label: 'Todas' },
        { value: 'Aguardando confirmação de estoque', label: 'Aguardando confirmação de estoque' },
        { value: 'Aguardando ponto de troca', label: 'Aguardando ponto de troca' },
        { value: 'Aguardando troca', label: 'Aguardando troca' },
        { value: 'Troca realizada', label: 'Troca realizada' },
        { value: 'Troca cancelada', label: 'Troca cancelada' }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.solicitacoesApiUrl);
                const result = await response.json();
    
                const filteredData = result.filter((item: any) => {
                    const statusMatch = statusSelected === "Todas" || item.status === statusSelected;
                    const searchMatch = item.protocolo_sanofi.includes(searchTerm);
    
                    if (unidade_usuario !== "0") {
                        const unidadeMatch = 
                            unidade_usuario === String(item.pharm_a) || 
                            unidade_usuario === String(item.pharm_b) || 
                            unidade_usuario === String(item.pharm_c);
                        
                        return statusMatch && searchMatch && unidadeMatch;
                    }
    
                    return statusMatch && searchMatch;
                });
    
                const formattedData = filteredData.map((item: any) => ({
                    id_solicitacao: item.id_solicitacao,
                    type: item.type,
                    status: item.status, 
                    medicamento: item.nome_medicamento,
                    childrenValidation: item.status === 'Aguardando confirmação de estoque' ||
                                        item.status === 'Aguardando troca',
                    tagColor: item.status === 'Aguardando confirmação de estoque' ? '#E3096F' :
                              item.status === 'Aguardando ponto de troca' ? '#3086E3' :
                              item.status === 'Aguardando troca' ? '#E44C4E' :
                              item.status === 'Troca realizada' ? '#4ce48b' :
                              item.status === 'Troca cancelada' ? '#8d8d8d' : '#FFF',
                    num_protocolo: item.protocolo_sanofi,
                    ean: item.ean,
                    fullDetails: item,
                    criation: item.dt_criacao
                }));
    
                const sortedData = formattedData.sort((a: IData, b: IData) => 
                    new Date(b.criation).getTime() - new Date(a.criation).getTime()
                );
                
                setData(sortedData);
            } catch (error) {
                console.error('Erro ao buscar os dados da API:', error);
            }
        };
    
        fetchData();
    }, [statusSelected, searchTerm, unidade_usuario]);    
    

    const openDetails = (request: any) => {
        setSelectedRequest(request);
        setIsDetailsOpen(true);
    };

    const closeDetails = () => {
        setIsDetailsOpen(false);
        setSelectedRequest(null);
    };

    const openEdit = (request: any) => {
        setSelectedRequest(request);
        setIsEditOpen(true); 
    };

    const closeEdit = () => {
        setIsEditOpen(false); 
        setSelectedRequest(null);
    };

    const openCancel = (request: any) => {
        setSelectedRequest(request);
        setIsCancelOpen(true); 
    };

    const closeCancel = () => {
        setIsCancelOpen(false); 
        setSelectedRequest(null);
    };

    const openCreate = (request: any) => {
        setIsCreateOpen(true); 
    };

    const closeCreate = () => {
        setIsCreateOpen(false); 
    };

    const openConfirmation = (request: any) => {
        setSelectedRequest(request);
        setIsConfirmationOpen(true); 
    };

    const closeConfirmation = () => {
        setIsConfirmationOpen(false); 
        setSelectedRequest(null);
    };


    const handleUpdate = (updatedRequest: any) => {
        setData(prevData => prevData.map(item => 
            item.id_solicitacao === updatedRequest.id_solicitacao ? { ...item, ...updatedRequest } : item
        ));
        closeEdit();
    };

    const handleYesClick = async (item: any) => {
        let nome_pharm = "";
        let cnpj_pharm = "";
        if (item.pharm_a === parseInt(unidade_usuario as string)) {
            nome_pharm = item.nome_pharm_a;
            cnpj_pharm = item.cnpj_pharm_a;
        } else if (item.pharm_b === parseInt(unidade_usuario as string)) {
            nome_pharm = item.nome_pharm_b;
            cnpj_pharm = item.cnpj_pharm_b;
        } else if (item.pharm_c === parseInt(unidade_usuario as string)) {
            nome_pharm = item.nome_pharm_c;
            cnpj_pharm = item.cnpj_pharm_c;
        }
        const updatedRequest = {
            ...item,
            nome_pharm_a: nome_pharm,
            nome_pharm_b: "",
            nome_pharm_c: "",
            cnpj_pharm_a: cnpj_pharm,
            cnpj_pharm_b: "",
            cnpj_pharm_c: "",
            pharm_a: parseInt(unidade_usuario as string),
            pharm_b: 0,
            pharm_c: 0,
            status: "Aguardando ponto de troca",
            status_wpp: "enviar_2"
        };
        try {
            console.log(updatedRequest)
            await axios.put(`${config.solicitacoesApiUrl}${item.id_solicitacao}/`, updatedRequest);
            handleUpdate(updatedRequest); 
            window.location.reload();
        } catch (error) {
            console.error('Erro ao confirmar estoque:', error);
        }
    };

    const handleNoClick = async (item: any) => {
        const updatedRequest = { ...item };
        if (String(item.pharm_a) === unidade_usuario) {
            updatedRequest.pharm_a = 0;
            updatedRequest.nome_pharm_a = "";
            updatedRequest.cnpj_pharm_a = "";
        } else if (String(item.pharm_b) === unidade_usuario) {
            updatedRequest.pharm_b = 0;
            updatedRequest.nome_pharm_b = "";
            updatedRequest.cnpj_pharm_b = "";
        } else if (String(item.pharm_c) === unidade_usuario) {
            updatedRequest.pharm_c = 0;
            updatedRequest.nome_pharm_c = "";
            updatedRequest.cnpj_pharm_c = "";
        }

        try {
            await axios.put(`${config.solicitacoesApiUrl}${item.id_solicitacao}/`, updatedRequest);

            if (updatedRequest.pharm_a === 0 && updatedRequest.pharm_b === 0 && updatedRequest.pharm_c === 0) {
                const cancelRequest = {
                    ...updatedRequest,
                    status: "Troca cancelada",
                    detalhe: "Nenhuma farmácia próxima com estoque disponível.",
                    status_wpp: "enviar_5"
                };
                await axios.put(`${config.solicitacoesApiUrl}${item.id_solicitacao}/`, cancelRequest);
                handleUpdate(cancelRequest);
            } else {
                handleUpdate(updatedRequest);
            }
            window.location.reload();
        } catch (error) {
            console.error('Erro ao negar estoque:', error);
        }
    };

    return (
        <Container>
            <ContentHeader title={titleOptions.title}>
                <SelectInput options={status} onChange={(e) => setStatusSelected(e.target.value)} defaultValue={statusSelected} />
                <SearchInputSwap onSearch={setSearchTerm} />
                {tipo === "sanofi" && ( 
                    <button className='create' onClick={openCreate}>
                        {"Criar solicitação"} <FcPlus className="add-icon" />
                    </button>
                )}
            </ContentHeader>

            <Filters>
                <button type="button" className="tag-filter AC">
                    Aguardando confirmação de estoque
                </button>
                <button type="button" className="tag-filter APT">
                    Aguardando ponto de troca
                </button>
                <button type="button" className="tag-filter AT">
                    Aguardando troca
                </button>
                <button type="button" className="tag-filter TR">
                    Troca realizada
                </button>
                <button type="button" className="tag-filter TC">
                    Troca cancelada
                </button>
            </Filters>

            <Content>
                {data.map(item => (
                    <SwapCard
                        key={item.id_solicitacao}
                        tagColor={item.tagColor}
                        title={tipo === "sanofi" ? "#" + item.num_protocolo : item.medicamento}
                        subtitle={tipo === "sanofi" ? item.medicamento : "#" + item.num_protocolo}
                        ean={item.ean}
                        criation={item.criation}>
                        <Confirmations>
                            {tipo === "sanofi" && (
                                <>
                                    <button className='details' onClick={() => openDetails(item.fullDetails)}>Detalhes</button>
                                    <button className="edit" onClick={() => openEdit(item.fullDetails)}>Editar</button>
                                    <button className="cancel" onClick={() => openCancel(item.fullDetails)}>Cancelar troca</button>
                                </>
                            )}
                            {tipo === "farmacia" && item.childrenValidation && item.status === "Aguardando confirmação de estoque" ? (
                                <>
                                    <button className='yes' onClick={() => handleYesClick(item.fullDetails)}>
                                        <FaRegThumbsUp />
                                    </button>
                                    <button className='no' onClick={() => handleNoClick(item.fullDetails)}>
                                        <FaRegThumbsDown />
                                    </button>
                                </>
                            ) : tipo === "farmacia" && item.childrenValidation && item.status === "Aguardando troca" ? (
                                <>
                                    <button className='yes' onClick={() => openConfirmation(item.fullDetails)}>
                                        <FaRegThumbsUp />
                                    </button>
                                </>
                            ) : null}
                        </Confirmations>
                    </SwapCard>
                ))}
            </Content>

            <SwapDetails 
                isOpen={isDetailsOpen} 
                onClose={closeDetails} 
                data={selectedRequest} 
            />

            <SwapEdit
                isOpen={isEditOpen} 
                onClose={closeEdit}   
                data={selectedRequest} 
                onUpdate={handleUpdate} 
            />

            <SwapCancel 
                isOpen={isCancelOpen} 
                onClose={closeCancel} 
                data={selectedRequest} 
                onUpdate={handleUpdate} 
            />

            <SwapCreate
                isOpen={isCreateOpen} 
                onClose={closeCreate}   
            />

            <SwapConfirmation
                isOpen={isConfirmationOpen} 
                onClose={closeConfirmation}   
                data={selectedRequest} 
                onUpdate={handleUpdate} 
            />
        </Container>
    );
};

export default ListSwap;