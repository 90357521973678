import React, { createContext, useState, useContext } from "react";
import axios from 'axios';

interface IChildrenProps {
    children?: React.ReactNode;
}

interface IAuthContext {
    logged: boolean;
    nome_exibicao?: string;
    tipo?: string;
    unidade_usuario?: string;
    signIn(email: string, password: string): void;
    signOut(): void;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [logged, setLogged] = useState<boolean>(() => {
        return !!localStorage.getItem('@sac-ai:logged');
    });

    const [nomeExibicao, setNomeExibicao] = useState<string | undefined>(() => {
        return localStorage.getItem('@sac-ai:nomeExibicao') || undefined;
    });

    const [tipo, setTipo] = useState<string | undefined>(() => {
        return localStorage.getItem('@sac-ai:tipo') || undefined;
    });

    const [unidade, setUnidade] = useState<string | undefined>(() => {
        return localStorage.getItem('@sac-ai:unidade') || undefined;
    });

    const signIn = async (email: string, password: string) => {
        try {
            const response = await axios.get("https://backend.sacai.tech/api/User/");
            const users = response.data;

            const user = users.find(
                (user: any) => user.email === email && user.senha === password
            );

            if (user) {
                localStorage.setItem('@sac-ai:logged', 'true');
                localStorage.setItem('@sac-ai:nomeExibicao', user.nome);
                localStorage.setItem('@sac-ai:tipo', user.tipo_user);
                localStorage.setItem('@sac-ai:unidade', user.unidade_usuario);
                setLogged(true);
                setNomeExibicao(user.nome);
                setTipo(user.tipo_user);
                setUnidade(user.unidade_usuario)
                window.location.href = "/";
            } else {
                alert('Senha ou usuário inválidos!');
            }
        } catch (error) {
            console.error("Erro ao fazer login:", error);
            alert('Erro de servidor. Tente novamente mais tarde.');
        }
    };

    const signOut = () => {
        localStorage.removeItem('@sac-ai:logged');
        localStorage.removeItem('@sac-ai:nomeExibicao');
        localStorage.removeItem('@sac-ai:tipo');
        localStorage.removeItem('@sac-ai:unidade');
        setLogged(false);
        setNomeExibicao(undefined);
        setTipo(undefined);
        setUnidade(undefined);
        window.location.href = "/";
    };

    return (
        <AuthContext.Provider value={{ logged, nome_exibicao: nomeExibicao, tipo, unidade_usuario: unidade , signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth(): IAuthContext {
    const context = useContext(AuthContext);
    return context;
}

export { AuthProvider, useAuth };