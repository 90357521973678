import React, { useEffect, useState } from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import config from '../../config';

interface IUserDeleteProps {
    isOpen: boolean;
    onClose: () => void;
    data: any;
    onUpdate: (updatedRequest: any) => void;
}

const UserDelete: React.FC<IUserDeleteProps> = ({ isOpen, onClose, data, onUpdate }) => {

    const handleDelete = async () => {
        try {
            await axios.delete(`${config.usuariosApiUrl}${data.id_user}/`);
            onUpdate(data.id_user);
            window.location.reload();
        } catch (error) {
            console.error('Erro ao deletar usuário:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={onClose}>
                    <MdClose />
                </CloseButton>
                <h2>Confirmar exclusão de usuário?</h2>

                <div className="button-group" style={{ marginTop: '16px' }}>
                    <button className="yes" onClick={handleDelete}>Sim</button>
                    <button className="no" onClick={onClose}>Não</button>
                </div>
            </Content>
        </Container>
    );
};

export default UserDelete;