import React, { useMemo, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ContentHeader from "../../components/ContentHeader";
import { Confirmations, Container, Content } from "./styles";
import config from "../../config";
import { FcPlus } from "react-icons/fc";
import SearchInputUser from "../../components/SearchInputUser";
import SelectInput from "../../components/SelectInput";
import UserCard from "../../components/UserCard";
import UserDetails from "../../components/UserDetails";
import UserEdit from "../../components/UserEdit";
import UserDelete from "../../components/UserDelete";
import UserCreate from "../../components/UserCreate";

interface IData {
    id_user: string;
    email: string;
    senha: string;
    nome: string;
    tipo_user: string;
    fullDetails: any;
}

const ListUser: React.FC = () => {
    const { type } = useParams();
    const [data, setData] = useState<IData[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [tipoSelected, setTipoSelected] = useState<string>('Todos');
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false); 
    const [isEditOpen, setIsEditOpen] = useState(false); 
    const [isDeleteOpen, setIsDeleteOpen] = useState(false); 
    const [isCreateOpen, setIsCreateOpen] = useState(false);

    const titleOptions = useMemo(() => {
        return { title: 'Usuários' };
    }, [type]);

    const tipos = [
        { value: 'Todos', label: 'Todos' },
        { value: 'sanofi', label: 'Sanofi' },
        { value: 'farmacia', label: 'Farmácia'}
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.usuariosApiUrl);
                const result = await response.json();
    
                const filteredData = result.filter((item: any) => {
                    const tipoMatch = tipoSelected === "Todos" || item.tipo_user === tipoSelected;
                    const searchMatch = item.nome.toLowerCase().includes(searchTerm.toLowerCase()); 
                    return tipoMatch && searchMatch;
                });
    
                const formattedData = filteredData.map((item: any) => ({
                    id_user: item.id_user,
                    email: item.email,
                    senha: item.senha,
                    nome: item.nome,
                    tipo_user: item.tipo_user === "sanofi" ? "Sanofi" : "Farmácia",
                    fullDetails: item
                }));

                const sortedData = formattedData.sort((a: IData, b: IData) => 
                    a.nome.localeCompare(b.nome)
                );
    
                setData(sortedData);
            } catch (error) {
                console.error('Erro ao buscar os dados da API:', error);
            }
        };
    
        fetchData();
    }, [tipoSelected, searchTerm]);    

    const openDetails = (request: any) => {
        setSelectedUser(request);
        setIsDetailsOpen(true);
    };

    const closeDetails = () => {
        setIsDetailsOpen(false);
        setSelectedUser(null);
    };

    const openEdit = (request: any) => {
        setSelectedUser(request);
        setIsEditOpen(true); 
    };

    const closeEdit = () => {
        setIsEditOpen(false); 
        setSelectedUser(null);
    };

    const openDelete = (request: any) => {
        setSelectedUser(request);
        setIsDeleteOpen(true); 
    };

    const closeDelete = () => {
        setIsDeleteOpen(false); 
        setSelectedUser(null);
    };

    const openCreate = (request: any) => {
        setIsCreateOpen(true); 
    };

    const closeCreate = () => {
        setIsCreateOpen(false); 
    };

    const handleUpdate = (updatedRequest: any) => {
        setData(prevData => prevData.map(item => 
            item.id_user === updatedRequest.id_user ? { ...item, ...updatedRequest } : item
        ));
        closeEdit();
    };

    return (
        <Container>
            <ContentHeader title={titleOptions.title}>
                <SelectInput options={tipos} onChange={(e) => setTipoSelected(e.target.value)} defaultValue={tipoSelected} />
                <SearchInputUser onSearch={setSearchTerm} />
                <button className='register-user' onClick={openCreate}>{"Cadastrar usuário"} <FcPlus className="add-icon"/></button>
            </ContentHeader>

            <Content>
                {data.map(item => (
                    <UserCard
                        key={item.id_user}
                        title={item.nome}
                        subtitle={item.tipo_user}>
                        <Confirmations>
                            <>
                                <button className='details' onClick={() => openDetails(item.fullDetails)}>Detalhes</button>
                                <button className="edit" onClick={() => openEdit(item.fullDetails)}>Editar</button>
                                <button className="cancel" onClick={() => openDelete(item.fullDetails)}>Apagar</button>
                            </>
                        </Confirmations>
                    </UserCard>
                ))}
            </Content>

            <UserDetails 
                isOpen={isDetailsOpen} 
                onClose={closeDetails} 
                data={selectedUser} 
            />

            <UserEdit
                isOpen={isEditOpen} 
                onClose={closeEdit}   
                data={selectedUser} 
                onUpdate={handleUpdate} 
            />

            <UserDelete 
                isOpen={isDeleteOpen} 
                onClose={closeDelete} 
                data={selectedUser} 
                onUpdate={handleUpdate} 
            />

            <UserCreate
                isOpen={isCreateOpen} 
                onClose={closeCreate}   
            />
        </Container>
    );
};

export default ListUser;