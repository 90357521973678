import React, { useState } from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import config from '../../config';

interface ISwapCreateProps {
    isOpen: boolean;
    onClose: () => void;
}

const SwapCreate: React.FC<ISwapCreateProps> = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        protocolo_sanofi: '',
        nome_cliente: '',
        cpf: '',
        email: '',
        telefone_cliente: '',
        cep_cliente: '',
        detalhe: '',
        nome_medicamento: '',
        ean: ''
    });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const validateForm = () => {
        const {
            protocolo_sanofi,
            nome_cliente,
            cpf,
            email,
            telefone_cliente,
            cep_cliente,
            nome_medicamento,
            ean,
            detalhe
        } = formData;
    
        if (!protocolo_sanofi || !nome_cliente || !cpf || !email || !telefone_cliente || !cep_cliente || !nome_medicamento || !ean || !detalhe) {
            return 'Todos os campos são obrigatórios.';
        }
    
        if (protocolo_sanofi.length !== 8) {
            return 'Protocolo inválido (Digite 8 caracteres númericos).';
        }
        if (cpf.length !== 11) {
            return 'CPF inválido (Digite apenas números).';
        }
        if (ean.length !== 13) {
            return 'EAN inválido (Digite 13 caracteres númericos).';
        }
        if (telefone_cliente.length !== 13) {
            return 'Celular inválido (Siga o exemplo: 5511999999999).';
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Email inválido.';
        }

        return null;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const validationError = validateForm();
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        const payload = {
            ...formData,
            status: 'Aguardando confirmação de estoque',
            pharm_a: 0,
            pharm_b: 0,
            pharm_c: 0
        };

        try {
            await axios.post(`${config.solicitacoesApiUrl}`, payload);
            window.location.reload();
        } catch (error) {
            setErrorMessage('Erro ao criar solicitação. Verifique os dados.');
            console.error('Erro ao criar solicitação:', error);
        }
    };

    const handleClose = () => {
        setFormData({
            protocolo_sanofi: '',
            nome_cliente: '',
            cpf: '',
            email: '',
            telefone_cliente: '',
            cep_cliente: '',
            detalhe: '',
            nome_medicamento: '',
            ean: ''
        });
        setErrorMessage(null);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={handleClose}>
                    <MdClose />
                </CloseButton>
                <h2>Criar Solicitação</h2>

                <form onSubmit={handleSubmit}>
                    <p className='title'><strong>Protocolo:</strong></p>
                    <input
                        type="text"
                        name="protocolo_sanofi"
                        value={formData.protocolo_sanofi}
                        onChange={handleChange}
                        maxLength={8}
                    />

                    <p className='title'><strong>Nome:</strong></p>
                    <input
                        type="text"
                        name="nome_cliente"
                        value={formData.nome_cliente}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>CPF:</strong></p>
                    <input
                        type="text"
                        name="cpf"
                        value={formData.cpf}
                        onChange={handleChange}
                        maxLength={11}
                    />

                    <p className='title'><strong>Email:</strong></p>
                    <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>Celular:</strong></p>
                    <input
                        type="text"
                        name="telefone_cliente"
                        value={formData.telefone_cliente}
                        onChange={handleChange}
                        maxLength={13}
                    />

                    <p className='title'><strong>CEP:</strong></p>
                    <input
                        type="text"
                        name="cep_cliente"
                        value={formData.cep_cliente}
                        onChange={handleChange}
                        maxLength={8}
                    />

                    <p className='title'><strong>Medicamento:</strong></p>
                    <input
                        type="text"
                        name="nome_medicamento"
                        value={formData.nome_medicamento}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>EAN:</strong></p>
                    <input
                        type="text"
                        name="ean"
                        value={formData.ean}
                        onChange={handleChange}
                        maxLength={13}
                    />

                    <p className='title'><strong>Detalhes:</strong></p>
                    <input
                        type="text"
                        name="detalhe"
                        value={formData.detalhe}
                        onChange={handleChange}
                    />

                    {errorMessage && (
                        <div style={{ fontSize:'16px', color: 'red', textAlign: 'center', gridColumn: 'span 4' }}>
                            {errorMessage}
                        </div>
                    )}

                    <button type="submit" style={{ marginTop: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                        Criar
                    </button>
                </form>
            </Content>
        </Container>
    );
};

export default SwapCreate;