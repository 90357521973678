import React from "react";
import { Container, Tag, Confirmations } from "./styles";

interface ISwapCard {
    tagColor: string; 
    title: string; 
    subtitle: string; 
    ean: string; 
    criation: string;
    children?: React.ReactNode; 
}

const Content: React.FC<ISwapCard> = ({tagColor, title, subtitle, ean, criation, children}) => {
    
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    return (
        <Container>
            <Tag color={tagColor}/>
            <div>
                <span>{title}</span>
                <small>{subtitle}</small>
            </div>
            <div className="infos">
                <small>EAN: {ean}</small>
                <small>{formatDate(criation)}</small>
            </div>
            
            <Confirmations>
                {children}
            </Confirmations>
        </Container>
    );
};

export default Content;