import React, { useState } from "react";
import Toggle from "../Toogle";
import { MdOutlineSwapHorizontalCircle, MdHome, MdExitToApp, MdClose, MdMenu, MdLocalHospital } from 'react-icons/md';
import { Container, Header, LogoImg, MenuContainer, MenuItemLink, MenuItemButton, ToggleMenu, ThemeToggleFooter } from "./styles";
import { useAuth } from "../../hooks/auth";
import { useTheme } from "../../hooks/theme";
import { FaUsers } from "react-icons/fa6";
import logoDark from '../../assets/logoTemaDark.png';
import logoLight from '../../assets/logoTemaLight.png';

const Aside: React.FC = () => {
    const { signOut, tipo } = useAuth();
    const { toggleTheme, theme } = useTheme();

    const [toggleMenuIsOpened, setToggleMenuIsOpened] = useState(false);
    const [darkTheme, setDarkTheme] = useState(() => theme.title === 'dark' ? true : false);

    const handleToggleMenu = () => {
        setToggleMenuIsOpened(!toggleMenuIsOpened);
    }
    const handleChangeTheme = () => {
        setDarkTheme(!darkTheme);
        toggleTheme();
    }

    return (
        <Container menuisopen={+toggleMenuIsOpened}>
            <Header>
                <ToggleMenu onClick={handleToggleMenu}>
                    {toggleMenuIsOpened ? <MdClose /> : <MdMenu />}
                </ToggleMenu>
                {theme.title === 'dark' && (
                    <LogoImg src={logoDark} alt="Logo SAC AI" />
                )}
                {theme.title === 'light' && (
                    <LogoImg src={logoLight} alt="Logo SAC AI" />
                )}
            </Header>

            <MenuContainer>
                <MenuItemLink href="/">
                    <MdHome />
                    Home
                </MenuItemLink>

                <MenuItemLink href="/solicitacoes-de-troca">
                    <MdOutlineSwapHorizontalCircle />
                    Solicitações de troca
                </MenuItemLink>

                {tipo === 'sanofi' && (
                    <MenuItemLink href="/farmacias">
                        <MdLocalHospital />
                        Farmácias
                    </MenuItemLink>
                )}

                {tipo === 'sanofi' && (
                    <MenuItemLink href="/usuarios">
                        <FaUsers />
                        Usuários
                    </MenuItemLink>
                )}  

                <MenuItemButton onClick={signOut}>
                    <MdExitToApp />
                    Sair
                </MenuItemButton>

                <ThemeToggleFooter menuisopen={+toggleMenuIsOpened}>
                    <Toggle
                        labelLeft="Dark"
                        labelRight="Light"
                        checked={darkTheme}
                        onChange={handleChangeTheme}
                    />
                </ThemeToggleFooter>
            </MenuContainer>
        </Container>
    );
}

export default Aside;