export default {
    title: 'light',

    colors: {
        primary: '#DCDCDC',
        secondary: '#FFF',
        tertiary: '#F5F5F5',

        white: '#000',
        black: '#FFF',
        gray: '#BFBFBF',

        success: '#03BB85',
        info: '#7a00e6',
        warning: '#E44C4E'
    }
};