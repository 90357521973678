import React from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';

interface IUserDetailsProps {
    isOpen: boolean;
    onClose: () => void;
    data: any; 
}

const UserDetails: React.FC<IUserDetailsProps> = ({ isOpen, onClose, data }) => {
    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={onClose}><MdClose/></CloseButton>
                <h2>Detalhes do Usuário</h2>
                <>
                    <p className='title'><strong>Nome:</strong></p> <p>{data.nome}</p>
                    <p className='title'><strong>Permissão:</strong></p> <p>{data.tipo_user === "sanofi" ? "Sanofi" : "Farmácia"}</p>
                    <p className='title'><strong>Email:</strong></p> <p>{data.email}</p>

                    {data.tipo_user === "farmacia" && (
                        <>
                            <p className='title'><strong>Unidade:</strong></p> <p>{data.unidade_usuario}</p>
                        </>
                    )}
                </>
            </Content>
        </Container>
    );
};

export default UserDetails;