import React, {useMemo} from "react";
import { Container } from "./styles";
import statusACEImg from '../../assets/aguardando-confirmacao-de-estoque.png';
import statusAPTImg from '../../assets/aguardando-ponto-de-troca.png';
import statusATImg from '../../assets/aguardando-troca.png';
import statusTRImg from '../../assets/troca-realizada.png';
import statusTCImg from '../../assets/troca-cancelada.png';
import CountUp from 'react-countup'

interface IStatusBoxProps {
    title: string;
    numberOfRequests: number;
    icon: 'statusACE' | 'statusAPT' | 'statusAT' | 'statusTR' | 'statusTC';
    color: string;
}

const StatusBox: React.FC <IStatusBoxProps> = ({title, numberOfRequests, icon, color}) => {

    const iconSelected = useMemo(() => {
            switch (icon){
                case 'statusACE':
                    return statusACEImg;
                case 'statusAPT':
                    return statusAPTImg;
                case 'statusAT':
                    return statusATImg;
                case 'statusTR':
                    return statusTRImg;
                case 'statusTC':
                    return statusTCImg;
                default:
                    return undefined;  
            }
    },[icon]);

    const suffix = numberOfRequests == 1 ? " solicitação" : " solicitações";

    return(
        <Container color={color}>
            <span>{title}</span>
            <h1>
                <CountUp
                    className="count"
                    end={numberOfRequests}
                    suffix={suffix}
                    separator="."
                    decimalPlaces={0}
                />
            </h1>
            <img src={iconSelected} alt={title}></img>
        </Container>
    )
}

export default StatusBox;