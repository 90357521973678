import React from "react";
import { Container } from "./styles";

interface ISwapTrackingProps {
    status: string; 
}

const SwapTracking: React.FC<ISwapTrackingProps> = ({ status }) => {
    return (
        <Container>
            <div className="container">
                {status === 'Troca cancelada' ? (
                    <div className="row">
                        <div className="col-12 col-md-10 hh-grayBox pt45 pb20">
                            <p className="cancelada">A solicitação foi cancelada</p>
                            
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-12 col-md-10 hh-grayBox pt45 pb20">
                            <div className="row justify-content-between">
                                
                                <div className="order-tracking completed">
                                    <span className="is-complete"></span>
                                    <p>Aguardando confirmação de estoque<br/></p>
                                </div>

                                <div className={`order-tracking ${status === 'Aguardando confirmação de estoque' ? '' : 'completed'}`}>
                                    <span className="is-complete"></span>
                                    <p>Aguardando ponto de troca<br/></p>
                                </div>
                                
                                <div className={`order-tracking ${status === 'Aguardando troca' || status === 'Troca realizada' ? 'completed' : ''}`}>
                                    <span className="is-complete"></span>
                                    <p>Aguardando troca<br/><br/></p>
                                </div>

                                <div className={`order-tracking ${status == 'Troca realizada' ? 'completed' : ''}`}>
                                    <span className="is-complete"></span>
                                    <p>Troca realizada<br/><br/></p>
                                </div>

                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default SwapTracking;