import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    background-color: ${props => props.theme.colors.tertiary};
    border-radius: 10px;
    width: 600px;
    height: 200px;
    padding: 20px;
    overflow-y: auto;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr; 
    padding-top: 70px; 

    > h2 {
        padding: 15px;
        color: ${props => props.theme.colors.white};
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    > p {
        padding: 10px;
        color: ${props => props.theme.colors.white};
        border: 1px solid ${props => props.theme.colors.white};
        display: flex;
        align-items: center;
    }

    > .title {
        color: ${props => props.theme.colors.info};
        background-color: ${props => props.theme.colors.primary};
        text-align: left;
    }

    > p:not(.title) {
        text-align: left;
        background-color: ${props => props.theme.colors.secondary};
    }

    @media(max-width: 770px){
        > p {
            font-size: 15px;
        }
    }
    @media(max-width: 650px){
        border-radius: 5px;
        width: 500px;
        padding: 10px;
        padding-top: 70px; 

        > h2 {
            font-size: 15px;
            padding: 30px;
        }
        > p {
            font-size: 12px;
        }
    }
    @media(max-width: 600px){
        width: 300px;
        padding: 10px;
        padding-top: 50px;
        grid-template-columns: 1fr 2fr; 
        grid-template-rows: auto auto;

        > h2 {
            font-size: 11px;
            padding: 17px;
        }
        > p {
            font-size: 11px;
        }
    }
`;

export const CloseButton = styled.div`
    color: ${props => props.theme.colors.info};
    background-color: ${props => props.theme.colors.primary};
    border-radius: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
    transition: opacity .3s;
    &:hover {
        opacity: .8;
    }
`;