import React from "react";
import { Container, SideLeft, LegendContainer, Legend, SideRight } from "./styles";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

interface IPieChartProps {
    title: string,
    data: {
        name:string;
        value:number;
        percent:number;
        color:string;
    }[],
}

const PieChartBox: React.FC<IPieChartProps> = ({data, title}) => (
    <Container>
        <SideLeft>
            <h2>{title}</h2>
            <LegendContainer>
                {
                    data.map(indicator => (
                        <Legend 
                            key={indicator.name} 
                            color={indicator.color}>
                            <div>{indicator.percent}%</div>
                            <span>{indicator.name}</span>
                        </Legend>
                    ))
                }
            </LegendContainer>
        </SideLeft>

        <SideRight>
            <ResponsiveContainer>
                <PieChart>
                    <Pie data={data} dataKey="value">
                        {
                            data.map(indicator => (
                                <Cell 
                                    key={indicator.name} 
                                    fill={indicator.color}
                                    cursor="pointer"
                                />
                            ))
                        }
                    </Pie>
                    <Tooltip/>
                </PieChart>
            </ResponsiveContainer>
        </SideRight>
    </Container>
);

export default PieChartBox;