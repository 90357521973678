import React, { useState, useEffect } from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import config from '../../config';

interface ISwapEditProps {
    isOpen: boolean;
    onClose: () => void;
    data: any;
    onUpdate: (updatedRequest: any) => void;
}

const statusOptions = [
    { value: 'Aguardando confirmação de estoque', label: 'Aguardando confirmação de estoque' },
    { value: 'Troca realizada', label: 'Troca realizada' }
];

const SwapEdit: React.FC<ISwapEditProps> = ({ isOpen, onClose, data, onUpdate }) => {
    const [formData, setFormData] = useState({
        protocolo_sanofi: '',
        nome_cliente: '',
        cpf: '',
        email: '',
        telefone_cliente: '',
        cep_cliente: '',
        status: '',
        nome_medicamento: '',
        ean: '',
        detalhe: '',
        status_wpp: '' 
    });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        if (isOpen) {
            setFormData({
                protocolo_sanofi: data.protocolo_sanofi || '',
                nome_cliente: data.nome_cliente || '',
                cpf: data.cpf || '',
                email: data.email || '',
                telefone_cliente: data.telefone_cliente || '',
                cep_cliente: data.cep_cliente || '',
                status: data.status || '',
                nome_medicamento: data.nome_medicamento || '',
                ean: data.ean || '',
                detalhe: data.detalhe || '',
                status_wpp: data.status_wpp || ''
            });
            setErrorMessage(null); 
        }
    }, [isOpen, data]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const validateForm = () => {
        const {
            protocolo_sanofi,
            nome_cliente,
            cpf,
            email,
            telefone_cliente,
            nome_medicamento,
            ean,
            detalhe
        } = formData;
    
        if (!protocolo_sanofi || !nome_cliente || !cpf || !email || !telefone_cliente || !nome_medicamento || !ean || !detalhe) {
            return 'Todos os campos são obrigatórios.';
        }
    
        if (protocolo_sanofi.length !== 8) {
            return 'Protocolo inválido (Digite 8 caracteres númericos).';
        }
        if (cpf.length !== 11) {
            return 'CPF inválido (Digite apenas números).';
        }
        if (ean.length !== 13) {
            return 'EAN inválido (Digite 13 caracteres númericos).';
        }
        if (telefone_cliente.length !== 13) {
            return 'Celular inválido (Siga o exemplo: 5511999999999).';
        }
    
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return 'Email inválido.';
        }
    
        return null;
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const validationError = validateForm();
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        let updatedStatusWpp = formData.status_wpp;

        if (formData.status === 'Aguardando confirmação de estoque') {
            updatedStatusWpp = 'enviar_1';
        } else if (formData.status === 'Troca realizada') {
            updatedStatusWpp = 'enviar_4';
        }

        const updatedFormData = { ...formData, status_wpp: updatedStatusWpp };

        try {
            await axios.put(`${config.solicitacoesApiUrl}${data.id_solicitacao}/`, updatedFormData);
            onUpdate(updatedFormData); 
            window.location.reload();
        } catch (error) {
            setErrorMessage('Erro ao editar solicitação. Verifique os dados.'); 
            console.error('Erro ao atualizar dados:', error);
        }
    };

    const handleClose = () => {
        setErrorMessage(null); 
        onClose();
    };

    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={handleClose}>
                    <MdClose />
                </CloseButton>
                <h2>Editar Solicitação</h2>
                <form onSubmit={handleSubmit}>
                    <p className='title'><strong>Protocolo:</strong></p>
                    <input
                        type="text"
                        name="protocolo_sanofi"
                        value={formData.protocolo_sanofi}
                        onChange={handleChange}
                        maxLength={8}
                    />

                    <p className='title'><strong>Nome:</strong></p>
                    <input
                        type="text"
                        name="nome_cliente"
                        value={formData.nome_cliente}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>CPF:</strong></p>
                    <input
                        type="text"
                        name="cpf"
                        value={formData.cpf}
                        onChange={handleChange}
                        maxLength={11}
                    />

                    <p className='title'><strong>Email:</strong></p>
                    <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>Celular:</strong></p>
                    <input
                        type="text"
                        name="telefone_cliente"
                        value={formData.telefone_cliente}
                        onChange={handleChange}
                        maxLength={13}
                    />

                    <p className='title'><strong>CEP:</strong></p>
                    <input
                        type="text"
                        name="cep_cliente"
                        value={formData.cep_cliente}
                        onChange={handleChange}
                        readOnly
                    />

                    <p className='title'><strong>Status:</strong></p>
                    <select
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                    >
                        {!statusOptions.some(option => option.value === data.status) && (
                            <option value={data.status}>
                                {data.status}
                            </option>
                        )}
                        
                        {statusOptions.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>

                    <p className='title'><strong>Medicamento:</strong></p>
                    <input
                        type="text"
                        name="nome_medicamento"
                        value={formData.nome_medicamento}
                        onChange={handleChange}
                    />

                    <p className='title'><strong>EAN:</strong></p>
                    <input
                        type="text"
                        name="ean"
                        value={formData.ean}
                        onChange={handleChange}
                        maxLength={13}
                    />

                    <p className='title'><strong>Detalhes:</strong></p>
                    <input
                        type="text"
                        name="detalhe"
                        value={formData.detalhe}
                        onChange={handleChange}
                    />

                    {errorMessage && (
                        <div style={{ fontSize:'16px',color: 'red', textAlign: 'center', gridColumn: 'span 4'}}>
                            {errorMessage}
                        </div>
                    )}

                    <button type="submit">Salvar</button>
                </form>
            </Content>
        </Container>
    );
};

export default SwapEdit;