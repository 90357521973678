import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from "../pages/Home";
import Layout from "../components/Layout";
import ListPharm from "../pages/ListPharm";
import ListUser from "../pages/ListUser";
import ListSwap from "../pages/ListSwap";
import { useAuth } from '../hooks/auth';

const AppRoutes: React.FC = () => {
    const { tipo } = useAuth();

    return (
        <Layout>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/solicitacoes-de-troca" element={<ListSwap />} />
                
                {tipo === 'sanofi' ? (
                    <Route path="/farmacias" element={<ListPharm />} />
                ) : (
                    <Route path="/farmacias" element={<Navigate to="/" />} />
                )}

                {tipo === 'sanofi' ? (
                    <Route path="/usuarios" element={<ListUser />} />
                ) : (
                    <Route path="/usuarios" element={<Navigate to="/" />} />
                )}
            </Routes>
        </Layout>
    );
};

export default AppRoutes;