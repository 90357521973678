export default {
    title: 'dark',

    colors: {
        primary: '#000000',
        secondary: '#0A0A0A',
        tertiary: '#1C1C1C',

        white: '#FFF',
        black: '#000',
        gray: '#BFBFBF',

        success: '#03BB85',
        info: '#7a00e6',
        warning: '#E44C4E'
    }
};