import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.main``;

export const Confirmations = styled.div`
    > button {
        padding: 7px 15px;
        border-radius: 5px;
        margin-left: 7px;
    }

    > .yes {
        background-color: ${props => props.theme.colors.success};
    }

    > .no {
        background-color: ${props => props.theme.colors.warning};
    }

    > .details, .edit, .cancel{
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: ${props => props.theme.colors.primary};
        font-size: 14px;
        color: ${props => props.theme.colors.info};
        cursor: pointer;
        transition: opacity .3s;

        &:hover{
            opacity: .8;
        }
    }

    > .yes, .no {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 55px; 
        height: 30px;
        cursor: pointer;
        transition: opacity .3s;

        &:hover{
            opacity: .5;
        }
    }

    @media (max-width: 850px) {
        > button {
            padding: 4px 8px;
        }
        > .details, .edit, .cancel {
            display: block;
            width: 100%; 
            margin: 5px 0;
            text-align: center;
            font-size: 12px;
        }
    }

    @media (max-width: 600px) {
        > button {
            padding: 7px 15px;
        }
        > .details, .edit, .cancel {
            display: inline-flex;
            width: auto;
            margin: 5px;
        }
        
    }

    @media (max-width: 470px) {
        > button {
            padding: 4px 8px;
        }
        > .details, .edit, .cancel {
            display: block;
            width: 100%; 
            margin: 5px 0;
            text-align: center;
            font-size: 12px;
        }
    }
`;

export const Filters = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    
    > .tag-filter {
        font-size: 18px;
        font-weight: 500;
        background: none;
        color: ${props => props.theme.colors.white};
        margin: 0 40px;
        cursor: default;
    }

    > .tag-filter.AC{
        &::after {
            content: '';
            display: block;
            width: 70px;
            margin: 0 auto;
            border-bottom: 10px solid #E3096F;
        }
    }
    > .tag-filter.APT{
        &::after {
            content: '';
            display: block;
            width: 70px;
            margin: 0 auto;
            border-bottom: 10px solid #3086E3;
        }
    }
    > .tag-filter.AT{
        &::after {
            content: '';
            display: block;
            width: 70px;
            margin: 0 auto;
            border-bottom: 10px solid #E44C4E;
        }
    }
    > .tag-filter.TR{
        &::after {
            content: '';
            display: block;
            width: 70px;
            margin: 0 auto;
            border-bottom: 10px solid #4ce48b;
        }
    }
    > .tag-filter.TC{
        &::after {
            content: '';
            display: block;
            width: 70px;
            margin: 0 auto;
            border-bottom: 10px solid #8d8d8d;
        }
    }

    @media (max-width: 1050px){
        > .tag-filter {
            margin: 0 20px;
        }
    }

    @media (max-width: 890px){
        > .tag-filter {
            margin: 0 3px;
            font-size: 16px;
        }
    }
    @media (max-width: 710px){
        > .tag-filter {
            margin: 0 2px;
            font-size: 12px;
        }

        .tag-filter.AC, .tag-filter.APT, .tag-filter.AT, .tag-filter.TR, .tag-filter.TC{
            &::after {
                 width: 50px;
            }
        }
        
    }

    @media (max-width: 650px){
        > .tag-filter {
            margin: 0 2px;
            font-size: 11px;
        }
    }
`;
