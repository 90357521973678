import React, { useMemo, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ContentHeader from "../../components/ContentHeader";
import { Confirmations, Container, Content } from "./styles";
import PharmCard from "../../components/PharmCard";
import config from "../../config";
import SearchInputPharm from "../../components/SearchInputPharm";
import { FcPlus } from "react-icons/fc";
import PharmDetails from "../../components/PharmDetails";
import PharmEdit from "../../components/PharmEdit";
import PharmDelete from "../../components/PharmDelete";
import PharmCreate from "../../components/PharmCreate";

interface IData {
    id_farmacia: string;
    unidade: string,
    nome_farmacia: string;
    cep_farmacia: string;
    cnpj: string;
    telefone_farmacia: string;
    rua_farmacia: string;
    cidade: string;
    fullDetails: any;
}

const ListPharm: React.FC = () => {
    const { type } = useParams();
    const [data, setData] = useState<IData[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedPharm, setSelectedPharm] = useState<any>(null);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false); 
    const [isEditOpen, setIsEditOpen] = useState(false); 
    const [isDeleteOpen, setIsDeleteOpen] = useState(false); 
    const [isCreateOpen, setIsCreateOpen] = useState(false); 

    const titleOptions = useMemo(() => {
        return { title: 'Farmácias' };
    }, [type]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.farmaciasApiUrl);
                const result = await response.json();
        
                const filteredData = result.filter((item: any) => {
                    const searchMatch = item.unidade.toString().includes(searchTerm);
                    return searchMatch;
                });
        
                const formattedData = filteredData.map((item: any) => ({
                    id_farmacia: item.id_farmacia,
                    unidade: item.unidade,
                    nome_farmacia: item.nome_farmacia,
                    cep_farmacia: item.cep_farmacia,
                    cnpj: item.cnpj,
                    telefone_farmacia: item.telefone_farmacia,
                    rua_farmacia: item.rua_farmacia,
                    cidade: item.cidade,
                    fullDetails: item
                }));
        
                const sortedData = formattedData.sort((a: IData, b: IData) => 
                    Number(b.unidade) - Number(a.unidade)
                );
        
                setData(sortedData);
            } catch (error) {
                console.error('Erro ao buscar os dados da API:', error);
            }
        };        
    
        fetchData();
    }, [searchTerm]);

    const openDetails = (request: any) => {
        setSelectedPharm(request);
        setIsDetailsOpen(true);
    };

    const closeDetails = () => {
        setIsDetailsOpen(false);
        setSelectedPharm(null);
    };

    const openEdit = (request: any) => {
        setSelectedPharm(request);
        setIsEditOpen(true); 
    };

    const closeEdit = () => {
        setIsEditOpen(false); 
        setSelectedPharm(null);
    };

    const openDelete = (request: any) => {
        setSelectedPharm(request);
        setIsDeleteOpen(true); 
    };

    const closeDelete = () => {
        setIsDeleteOpen(false); 
        setSelectedPharm(null);
    };

    const openCreate = (request: any) => {
        setIsCreateOpen(true); 
    };

    const closeCreate = () => {
        setIsCreateOpen(false); 
    };

    const handleUpdate = (updatedRequest: any) => {
        setData(prevData => prevData.map(item => 
            item.id_farmacia === updatedRequest.id_farmacia ? { ...item, ...updatedRequest } : item
        ));
        closeEdit();
    };

    return (
        <Container>
            <ContentHeader title={titleOptions.title}>
                <SearchInputPharm onSearch={setSearchTerm} />
                <button className='register' onClick={openCreate}>{"Cadastrar farmácia"} <FcPlus className="add-icon"/></button>
            </ContentHeader>

            <Content>
                {data.map(item => (
                    <PharmCard
                        key={item.id_farmacia}
                        title={item.unidade}
                        subtitle={item.nome_farmacia}
                        cnpj={item.cnpj}>
                        <Confirmations>
                            <>
                                <button className='details' onClick={() => openDetails(item.fullDetails)}>Detalhes</button>
                                <button className="edit" onClick={() => openEdit(item.fullDetails)}>Editar</button>
                                <button className="cancel" onClick={() => openDelete(item.fullDetails)}>Apagar</button>
                            </>
                        </Confirmations>
                    </PharmCard>
                ))}
            </Content>

            <PharmDetails 
                isOpen={isDetailsOpen} 
                onClose={closeDetails} 
                data={selectedPharm} 
            />

            <PharmEdit
                isOpen={isEditOpen} 
                onClose={closeEdit}   
                data={selectedPharm} 
                onUpdate={handleUpdate} 
            />

            <PharmDelete 
                isOpen={isDeleteOpen} 
                onClose={closeDelete} 
                data={selectedPharm} 
                onUpdate={handleUpdate} 
            />

            <PharmCreate
                isOpen={isCreateOpen} 
                onClose={closeCreate}   
            />
        </Container>
    );
};

export default ListPharm;