import React, { useState, useEffect } from 'react';
import { Container, Content, CloseButton } from './styles';
import { MdClose } from 'react-icons/md';
import axios from 'axios';
import config from '../../config';

interface ISwapConfirmationProps {
    isOpen: boolean;
    onClose: () => void;
    data: any;
    onUpdate: (updatedRequest: any) => void;
}

const SwapConfirmation: React.FC<ISwapConfirmationProps> = ({ isOpen, onClose, data, onUpdate }) => {
    const [formData, setFormData] = useState({
        cpf: ''
    });

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const validateForm = () => {
        const {
            cpf
        } = formData;
    
        if (!cpf) {
            return 'Preencha o CPF para confirmar a troca.';
        }

        return null;
    };
    
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const validationError = validateForm();
        if (validationError) {
            setErrorMessage(validationError);
            return;
        }

        if (formData.cpf === data.cpf) {
            try {
                const formData = { 
                    ...data, 
                    status: 'Troca realizada',
                    status_wpp: "enviar_4",
                    detalhe: "Troca realizada com sucesso."
                };
                await axios.put(`${config.solicitacoesApiUrl}${data.id_solicitacao}/`, formData);
                onUpdate(formData); 
                window.location.reload();
            } catch (error) {
                setErrorMessage('Erro ao confirmar troca.'); 
                console.error('Erro ao atualizar dados:', error);
            }
        }
        else{
            setErrorMessage('Código incorreto, tente novamente.'); 
        }
    };

    const handleClose = () => {
        setFormData({
            cpf: ''
        });
        setErrorMessage(null); 
        onClose();
    };

    if (!isOpen) return null;

    return (
        <Container>
            <Content>
                <CloseButton className='close' onClick={handleClose}>
                    <MdClose />
                </CloseButton>
                <h2>Confirmar troca</h2>
                <form onSubmit={handleSubmit}>

                    <input
                        type="text"
                        name="cpf"
                        value={formData.cpf}
                        placeholder='Digite o CPF'
                        onChange={handleChange}
                        maxLength={11}
                    />

                    {errorMessage && (
                        <div style={{ fontSize:'16px',color: 'red', textAlign: 'center', gridColumn: 'span 4'}}>
                            {errorMessage}
                        </div>
                    )}

                    <button type="submit">Confirmar</button>
                </form>
            </Content>
        </Container>
    );
};

export default SwapConfirmation;