import React, { useMemo, useEffect, useState } from "react";
import { Container, Content } from "./styles";
import ContentHeader from "../../components/ContentHeader";
import StatusBox from "../../components/StatusBox";
import PieChartBox from "../../components/PieChartBox";
import BarChartBox from "../../components/BarChartBox";
import config from "../../config";
import { useAuth } from '../../hooks/auth'; 

const Home: React.FC = () => {
    const { unidade_usuario } = useAuth(); 
    const [swapRequests, setSwapRequests] = useState<any[]>([]);

    useEffect(() => {
        const fetchSwapRequests = async () => {
            try {
                const response = await fetch(config.solicitacoesApiUrl);
                const data = await response.json();
    
                const filteredData = data.filter((item: any) => {
                    if (unidade_usuario !== "0" && unidade_usuario) {
                        return (
                            unidade_usuario === String(item.pharm_a) ||
                            unidade_usuario === String(item.pharm_b) ||
                            unidade_usuario === String(item.pharm_c)
                        );
                    }
                    return true;
                });
    
                setSwapRequests(filteredData);
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        };
    
        fetchSwapRequests();
    }, [unidade_usuario]);

    const totalACE = useMemo(() => {
        let total: number = 0;
        swapRequests.forEach(item => {
            if (item.status === "Aguardando confirmação de estoque")
                total += 1;
        });
        return total;
    }, [swapRequests]);

    const totalAPT = useMemo(() => {
        let total: number = 0;
        swapRequests.forEach(item => {
            if (item.status === "Aguardando ponto de troca")
                total += 1;
        });
        return total;
    }, [swapRequests]);

    const totalAT = useMemo(() => {
        let total: number = 0;
        swapRequests.forEach(item => {
            if (item.status === "Aguardando troca")
                total += 1;
        });
        return total;
    }, [swapRequests]);

    const totalTR = useMemo(() => {
        let total: number = 0;
        swapRequests.forEach(item => {
            if (item.status === "Troca realizada")
                total += 1;
        });
        return total;
    }, [swapRequests]);

    const totalTC = useMemo(() => {
        let total: number = 0;
        swapRequests.forEach(item => {
            if (item.status === "Troca cancelada")
                total += 1;
        });
        return total;
    },[swapRequests])

    const relationStatus = useMemo(() => {
        const total = totalACE + totalAPT + totalAT + totalTR + totalTC;
        const percentACE = (totalACE / total) * 100;
        const percentAPT = (totalAPT / total) * 100;
        const percentAT = (totalAT / total) * 100;
        const percentTR = (totalTR / total) * 100;
        const percentTC = (totalTC / total) * 100;

        const data = [{
            name: "Aguardando confirmação de estoque",
            value: totalACE,
            percent: Number(percentACE.toFixed(1)) ? Number(percentACE.toFixed(1)) : 0,
            color: '#F20A76'
        }, {
            name: "Aguardando ponto de troca",
            value: totalAPT,
            percent: Number(percentAPT.toFixed(1)) ? Number(percentAPT.toFixed(1)) : 0,
            color: '#3086E3'
        }, {
            name: "Aguardando troca",
            value: totalAT,
            percent: Number(percentAT.toFixed(1)) ? Number(percentAT.toFixed(1)) : 0,
            color: '#E44C4E'
        }, {
            name: "Troca realizada",
            value: totalTR,
            percent: Number(percentTR.toFixed(1)) ? Number(percentTR.toFixed(1)) : 0,
            color: '#4ce48b'
        }, {
            name: "Troca cancelada",
            value: totalTC,
            percent: Number(percentTC.toFixed(1)) ? Number(percentTC.toFixed(1)) : 0,
            color: '#8d8d8d'
        }];

        return data;

    }, [totalACE, totalAPT, totalAT, totalTR, totalTC]);

    const relationInProgressVsCompleteVsCancel = useMemo(() => {
        const total = totalACE + totalAPT + totalAT + totalTR + totalTC;
        const totalInProgess = totalACE + totalAPT + totalAT;
        const totalCompleted = totalTR;
        const totalCancel = totalTC;
        const percentInProgess = (totalInProgess / total) * 100;
        const percentCompleted = (totalCompleted / total) * 100;
        const percentCancel = (totalCancel / total) * 100;

        return [{
            name: 'Em andamento',
            total: totalInProgess,
            percent: Number(percentInProgess.toFixed(1)) ? Number(percentInProgess.toFixed(1)) : 0,
            color: "#fbff00a5"
        }, {
            name: 'Concluído',
            total: totalCompleted,
            percent: Number(percentCompleted.toFixed(1)) ? Number(percentCompleted.toFixed(1)) : 0,
            color: "#4ce48b"
        }, {
            name: 'Cancelado',
            total: totalCancel,
            percent: Number(percentCancel.toFixed(1)) ? Number(percentCancel.toFixed(1)) : 0,
            color: "#8d8d8d"
        }]
    }, [totalACE, totalAPT, totalAT, totalTR]);

    return (
        <Container>
            <ContentHeader title="Home">
            </ContentHeader>

            <Content>
                <StatusBox 
                    title={`Aguardando confirmação\nde estoque`}
                    numberOfRequests={totalACE} 
                    icon="statusACE"
                    color="#F20A76"
                />
                <StatusBox 
                    title={`Aguardando ponto\nde troca`} 
                    numberOfRequests={totalAPT} 
                    icon="statusAPT"
                    color="#3086E3"
                />
                <StatusBox 
                    title="Aguardando troca" 
                    numberOfRequests={totalAT} 
                    icon="statusAT"
                    color="#E44C4E"
                />
                <StatusBox 
                    title="Troca realizada" 
                    numberOfRequests={totalTR} 
                    icon="statusTR"
                    color="#4ce48b"
                />
                <StatusBox
                    title="Troca cancelada"
                    numberOfRequests={totalTC}
                    icon="statusTC"
                    color="#8d8d8d"
                />

                <PieChartBox 
                    data={relationStatus}
                    title="Relação dos status"
                />

                <BarChartBox 
                    data={relationInProgressVsCompleteVsCancel}
                    title="Progresso de conclusão"
                />
            </Content>
        </Container>
    );
}

export default Home;
