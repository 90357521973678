import styled, { keyframes } from "styled-components";

interface ITagProps {
    color: string;
}

const animate = keyframes`
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    50% {
        opacity: .3;
    }
    100% {
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const Container = styled.div`
    background-color: ${props => props.theme.colors.tertiary};
    list-style: none;
    border-radius: 10px;
    margin: 10px 0;
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    animation: ${animate} .5s ease;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 10px;
    }

    > .infos {
        transform: translate(-50%, -50%);
        top: 50%;
        right: 20%;
        position: absolute;
    }

    > div span {
        font-size: 22px;
        font-weight: 500;
    }

    @media (max-width: 1550px) {
        > .infos {
            right: 25%;
        }
    }

    @media (max-width: 1310px) {
        > .infos {
            right: 30%;
        }
    }

    @media (max-width: 1310px) {
        > .infos {
            right: 35%;
        }
    }

    @media (max-width: 1100px) {
        flex-direction: column;
        align-items: flex-start;
        height: 100px;
        
        > div {
            margin-bottom: 10px; 
        }
        
        > .infos {
            top: 0px;
            left: 0px;
            position: relative; 
            transform: none;
        }
    }

    @media (max-width: 910px) {
        > div span {
            font-size: 20px;
        }
    }

    @media (max-width: 870px) {
        > div span {
            font-size: 18px;
        }
    }

    @media (max-width: 660px) {
        > div, div span {
            font-size: 15px;
        }
    }

    @media (max-width: 620px) {
        > div, div span{
            font-size: 13px;
        }
    }
`;

export const Tag = styled.div<ITagProps>`
    width: 13px;
    height: 60%;
    background-color: ${props => props.color};
    position: absolute;
    left: 0;
`;

export const Confirmations = styled.div`
    display: flex;
    gap: 10px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: 10px;
`;
